import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import limparProductImageMapper from "../components/product-images"
import './category.css'
import limparImageMapper from "../components/limpar-svg-images"
import ReactMarkdown from 'react-markdown'

export const productsOfCategory = graphql`
    query productsQuery($slug: String) {
        product: allStrapiProduct(
        filter: {category: {slug: {eq: $slug}}}
        sort: {order: ASC, fields: meta___order}
        ) {
            nodes {
                title
                slug
                features {
                    feature
                }
                productData {
                    field
                    value
                }
                images {
                    localFile {
                        publicURL
                    }
                }
            }
        }
    }
`

const Category = ({ data, pageContext: { slug, category }}) => {
    return (
    <Layout headerColorMode="light" preFooterPreset="partners" pageTitle={category.title} enableBorderAnim>
        <section className="lp-categories-hero container text-center">
            <div className="row">
                <div className="col-12">
                    <h2 className="d-none d-lg-block">{ category.title }</h2>
                    {
                        category.text &&
                        <p>{ category.text }</p>
                    }
                    {
                        category.description &&
                        <ReactMarkdown className={`lp-cat-long-desc`}>{ category.description }</ReactMarkdown>
                    }
                </div>
            </div>
            <div className="row g-5 lp-category-list">
                {
                    data.product.nodes.map( (product, productIndex) => {
                        return (
                            <div key={productIndex} className="col-12 col-md-4">
                                <Link className="link-dark" to={`${product.slug}`}>
                                    {/* {edge.node.content.title} */}
                                    <div className="lp-categories-category text-center">
                                        <img
                                            className="img-fluid"
                                            src={
                                                product.images ?
                                                product.images[0].localFile.publicURL :
                                                limparImageMapper['logo-svg-light-mode']
                                            }
                                            alt="Limpar Model A9"
                                        />
                                        <h3 className="lp-sup-cat-title">
                                            {product.title}
                                        </h3>
                                    </div>
                                </Link>
                            </div>
                        )
                    })
                }
            </div>
        </section>
    </Layout>
    )
}

export const Head = ({ data, pageContext: { slug, category }}) => <Seo title={category.title} />


export default Category

